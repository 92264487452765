<template>
  <div>

    <!-- Media -->
    <b-media class="mb-2">

      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="adminData.avatar"
          :text=adminData.agencyName
          :variant="`light-primary`"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-1">
        {{ adminData.first_name }} {{adminData.last_name}}
      </h4>
      <!--
      <div class="d-flex flex-wrap">
        <b-button
          variant="primary"
          @click="$refs.refInputEl.click()"
        >
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            @input="inputImageRenderer"
          >
          <span class="d-none d-sm-inline">Modifier</span>
          <feather-icon
            icon="EditIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
        <b-button
          variant="outline-secondary"
          class="ml-1"
        >
          <span class="d-none d-sm-inline">Supprimer</span>
          <feather-icon
            icon="TrashIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
      </div>
      -->
    </b-media>

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>

        <!-- Field: Username -->
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Prénom"
            label-for="firstName"
          >
            <b-form-input
              id="firstName"
              v-model="adminData.first_name"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Nom de famille"
            label-for="lastName"
          >
            <b-form-input
              id="lastName"
              v-model="adminData.last_name"
            />
          </b-form-group>
        </b-col>

        <!-- Field: phone Number -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Téléphone"
            label-for="phone_number"
          >
            <b-form-input
              id="phone_number"
              v-mask="'(###) ###-####'"
              v-model="adminData.phone_number"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Courriel"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="adminData.email"
              type="email"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Role -->
        <b-col
          cols="12"
          md="6"
        >
            <b-form-group
              label="Rôle"
              label-for="user-role"
            >

              <v-select
                :options="roleOptions"
                v-model="adminData.role"
                label="label"
                :reduce="label => label.value"
                input-id="user-role"
              />

            </b-form-group>
        </b-col>

        <b-col v-if="pcVoyagesEnabled" sm="6">
          <b-form-group :label="$t('user_profile.pcvoyages_agent_code')" label-for="agent-code">
            <b-form-input v-model="adminData.pc_voyages_agent_code" name="pcVoyagesAgentCode" placeholder="ABC"/>
          </b-form-group>
        </b-col>

        <b-col v-if="pcVoyagesEnabled" sm="6">
          <b-form-group :label="$t('user_profile.pcvoyages_agency_branch_code')" label-for="agency-branch-code">
            <b-form-input v-model="adminData.pc_voyages_agency_branch_code" name="pcVoyagesAgencyBranchCode" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1 mt-2"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click=updateCurrentUser
    >
      Enregistrer
    </b-button>
    <b-button
      variant="outline-secondary"
      class="mt-2"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      :to="{ name: 'administrators'}"
    >
      Annuler
    </b-button>
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import {useUserApi} from '@/views/administrators/composables/use-user-api';
import {useToastNotification} from "@/shared/composables/use-toast-notifications";
import {AUTH_STORE_NAMESPACE} from "@/modules/authnz/models/store";
import store from "@/store";
import roleConfig from '@core/data/roleConfig.js'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
  },
  props: {
    adminData: {
      type: Object,
      required: true,
    }
  },
  setup(props) {

    const {updateUser} = useUserApi();
    const {displaySuccessMessage, displayErrorMessage} = useToastNotification();
    const pcVoyagesEnabled = store.state[AUTH_STORE_NAMESPACE].orgSettings.pcvoyages_enable;

    const roleOptions = roleConfig.roles.filter(role => role.value !== 'agency_admin' && role.value !== 'agency_agent');

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.adminData.avatar = base64
    })

    const updateCurrentUser = () => {
      try {
        updateUser(props.adminData)
        displaySuccessMessage('Les changements ont été sauvegardés avec succès');
      } catch {
        displayErrorMessage('Une erreur est survenue en essayant de sauvegarder les changements');
      }
    }

    return {
      pcVoyagesEnabled,
      avatarText,
      roleOptions,
      updateCurrentUser,
      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
